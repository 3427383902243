<template>
  <div>
    <v-dialog v-model="dialogControl" persistent max-width="900px">
      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9; height: 670px">
        <v-card-text style="background-color: #f1f4f9" class="h-full">
          <v-row>
            <v-col cols="12" md="4" class="no-padding ml-n2">
              <v-card class="rounded-l-xl h-100" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                <v-card-title>
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="150px"
                    max-width="120px"
                    alt="logo"
                    contain
                  ></v-img>
                </v-card-title>
                <v-card-title class="text-center justify-center muller-capitalized"
                  >Mes Services Assurance</v-card-title
                >
                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                <v-card-text class="no-padding">
                  <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                </v-card-text>
                <v-card-actions class="mt-15"> Dernière mise à jour : {{ update }} </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" md="8" class="no-padding d-flex">
              <v-card class="flex-fill flex d-flex flex-column rounded-r-xl" flat>
                <v-card-title class="muller-capitalized EoleBlue--text">
                  Bonjour {{ $store.state.user.firstname }}
                </v-card-title>
                <v-card-subtitle> Bienvenu sur Mes Services Assurance </v-card-subtitle>

                <v-card-title class="text-center justify-center mt-10">D'où vous connectez-vous ?</v-card-title>
                <v-card-text class="text-center h-full">
                  <v-row class="text-center align-center d-inline-flex row-study">
                    <v-col cols="12" md="6" v-for="(item, index) in workTypes" v-bind:key="index">
                      <v-card
                        @mouseover="item.hovered = true"
                        @mouseleave="item.hovered = false"
                        v-on:click="setControl(item.type)"
                        outlined
                        width="200"
                        height="200"
                        elevation="3"
                        class="align-center d-flex rounded-xl"
                      >
                        <v-card-text class="text-center pa-5">
                          <i
                            :class="[
                              'mt-4 fa-solid ',
                              item.icon,
                              'fa-5x',
                              item.hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                            ]"
                          ></i>
                          <p class="mt-2">{{ item.type }}</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="text-center align-center d-inline-flex row-study">
                    <v-col cols="12" md="12">
                      <v-card
                        @mouseover="workTypeChoose = true"
                        @mouseleave="workTypeChoose = false"
                        v-on:click="setControl('Autre')"
                        outlined
                        width="425"
                        height="100"
                        elevation="3"
                        class="align-center d-flex rounded-xl"
                      >
                        <v-card-text class="text-center pa-5">
                          <i
                            class="mt-4 fa-solid fa-briefcase fa-3x"
                            :class="workTypeChoose ? 'EoleYellow--text' : 'EoleBlue--text'"
                          ></i>
                          <p class="mt-2">Je suis un prestataire externe</p>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider class="my-4 mx-2"></v-divider>
                <v-card-text v-if="!!IPInformations" class="no-padding">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-card-text>
                        <i class="fa-solid fa-location-dot EoleBlue--text mr-2"></i>{{ IPInformations.city }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="12" md="12" class="mt-n10">
                      <v-card-text>
                        <i class="fa-solid fa-server EoleBlue--text mr-2"></i>{{ IPInformations.ip }}
                      </v-card-text>
                    </v-col>

                    <v-col cols="12" md="12" class="mt-n10">
                      <v-card-text>
                        <i class="fa-solid fa-globe EoleBlue--text mr-2"></i>{{ IPInformations.org }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import config from "@/views/config/config"
import { getCookie, setCookie } from "@/utils/cookies"

export default {
  created() {
    this.getIPInformations()
  },
  data: () => ({
    update: config.informations.version,
    IPInformations: null,
    workTypeChoose: null,
    workTypes: [
      {
        id: 1,
        type: "Au bureau",
        icon: "fa-building",
        color: "EoleBlue--text",
        hovered: false,
      },
      {
        id: 2,
        type: "En télétravail",
        icon: "fa-laptop",
        color: "EoleBlue--text",
        hovered: false,
      },
    ],
    dialogControl: true,
  }),
  methods: {
    async setControl(controller) {
      const idAccount = this.$store.state.user.id

      const response = await fetch(`${config.apiUri}/accounts/${idAccount}/set-connexion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
        body: JSON.stringify({
          type: controller,
          ip: this.IPInformations.ip,
          location: this.IPInformations.city,
          provider: this.IPInformations.org,
        }),
      })

      if (response.ok) {
        this.dialogControl = false

        setCookie("controlled", true, 14400, true, "Strict")

        this.$toast.success("Votre connexion a été enregistrée avec succès", {
          position: "bottom-right",
          duration: 5000,
        })
      }
    },
    getIPInformations() {
      fetch("https://ipapi.co/json/")
        .then(response => response.json())
        .then(data => {
          this.IPInformations = data
        })
    },
  },
}
</script>
