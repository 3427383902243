// utils/routes.js
import router from "../router";
import store from "../store/store";

export function getAccessibleRoutes(userRights, userSubscription) {
  console.log("User Rights:", userRights);
  console.log("User Subscription:", userSubscription);

  const accessibleRoutes = router.options.routes.filter(route => {
    const {
      rights,
      subscription
    } = route.meta || {};
    const hasAccessByRights = rights ? rights.includes(userRights) : true;
    const hasAccessBySubscription = subscription ? subscription.includes(userSubscription) :
      true;

    console.log(
      `Route: ${route.path}, Rights: ${rights}, Subscription: ${subscription}, Access by Rights: ${hasAccessByRights}, Access by Subscription: ${hasAccessBySubscription}`
    );

    return hasAccessByRights && hasAccessBySubscription;
  });

  return accessibleRoutes;
}


export function checkRouteIDExist(routeID) {
  const routeExists = store.state.authorizedRoutes.some(route => {
    return route.id_route === routeID;
  });

  return routeExists;
}
